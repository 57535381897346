import { useRef } from 'react'
export const useSubtitle = ({ playerRef }) => {
  const arrSubtitleRef = useRef([])
  const stream_embeded  = true

  const handleTextTrackStream = () => {
    const player = playerRef.current
    if (!player) return
    const textTracks = player.textTracks()
    textTracks.addEventListener('addtrack', handleTrackEvent, false)

    textTracks.addEventListener('removetrack', event => {
      const track = event?.track
      console.log('Text track: remove success', track)

      track.removeEventListener('cuechange', function () {})
    })

    // textTracks.addEventListener('cuechange', () => {
    //   const activeCue = textTracks[0].activeCues[0]
    //   console.log(activeCue.text, activeCue.startTime, activeCue.endTime)
    // })
  }

  const handleTextTrackStreamWhenPlayerInitAfter = player => {
    if (!player) return
    console.log('Text track:', 'handleTextTrackStreamWhenPlayerInitAfter')
    const tracks = player?.textTracks()
    if (tracks?.length) {
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        handleTrackEvent({ track })
      }
    }
  }

  const handleTrackEvent = event => {
    const arr = arrSubtitleRef.current || []
    const track = event?.track

    console.log('Text track:' , track.label, track.language, 'add')

    if (!track) return
    if (track.kind !== 'metadata') {
      const trackLanguage = {
        ...track,
        language_name: track?.label,
        id: track?.id,
        subtitle_vtt_url: 'streaming',
        index: !arr?.length ? arr?.length + 1 : arr?.length,
        language_code: track.language,
        idx: !arr?.length ? arr?.length + 1 : arr?.length
      }
      arr.push(trackLanguage)
      arrSubtitleRef.current = arr
    }
  }

  const selectActiveSubFirst = () => {
    let tracks = playerRef?.current?.textTracks()

    if (tracks?.length) {
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        track.mode = stream_embeded ? 'disabled' : 'hidden'

        if (
          (track.kind === 'subtitles' || track.kind === 'captions')
        ) {
          console.log('Text track: add first')
          track.mode = 'showing'
        }
      }
    }
  }

  return {
    handleTextTrackStream,
    handleTextTrackStreamWhenPlayerInitAfter,
    selectActiveSubFirst
  }

}