import React, { useMemo } from 'react'
import videojs from 'video.js'
import { muxConfig } from './plugins/mux'
import initDrm  from 'features/player/components/drm'
import { useSelector } from 'react-redux'
import { getVerisonOs } from 'common/utils'

import 'videojs-mux'
import 'videojs-shaka'
import './styles/video.min.css'
import { useHls } from 'features/player/hooks/useHls'

export const VideoLiveJS = (props) => {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const { onReady, src, player, videoIdTag } = props
  const playerData = useSelector((state) => state.player.playerData)
  const { account } = useSelector((state) => state.login)
  useHls({ playerData })
  const manifestUrl = useMemo(
    () => playerData?.playInfo?.hls_link_play || playerData.linkPlay,
    [playerData]
  )
  React.useEffect(() => {
    if (!manifestUrl) return
    const isDrmQnet = playerData?.drmSession?.merchant === 'qnet'
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const optionsCustome = {
        loop: false,
        techOrder: isDrmQnet ? ['shaka', 'html5'] : ['html5'],
        autoplay: true,
        muted: false,
        controls: true,
        preload: 'auto',
        bigPlayButton: false,
        inactivityTimeout: 0,
        errorDisplay: false,
        html5: {
          nativeTextTracks: true,
          hlsjsConfig: {
            capLevelToPlayerSize: false,
            enableWorker: window.platform === 'tv_webos' ? false : true,

            // maxBufferLength: 20,
            // maxMaxBufferLength: 40,
            // backBufferLength: 10,
            // maxBufferSize: 20 * 1000 * 1000,
            // startLevel: 0,
            startPosition: 0,
            // liveSyncDuration: 15,

            // Increase the EMA half life because we're fixed the bitrate estimate algorithm of hls.js to use segment duration as weight
            abrEwmaFastLive: 24, // Default: 3
            abrEwmaSlowLive: 48, // Default: 9

            // maxLoadingDelay: 1,
            // maxStarvationDelay: 1,

            // testBandwidth: getVerisonOs().startsWith('3') ? true : false,

            // stretchShortVideoTrack: true,
            // forceKeyFrameOnDiscontinuity: false,
            // maxAudioFramesDrift: 2,
            manifestLoadPolicy: {
              default: {
                maxTimeToFirstByteMs: 10000,
                maxLoadTimeMs: 30000,
                timeoutRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                },
                errorRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                }
              }
            },
            playlistLoadPolicy: {
              default: {
                maxTimeToFirstByteMs: 10000,
                maxLoadTimeMs: 30000,
                timeoutRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                },
                errorRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                }
              }
            }
          },
          nativeVideoTracks: false
        },
        plugins: {
          reloadSourceOnError: {},
          ...(muxConfig(playerData, account) || {})
        }
      }

      videojs.log.level('off')
      videojs.log.history.disable()

      const playerEl = (playerRef.current = videojs(
        videoElement,
        optionsCustome
      ))
      if (isDrmQnet) {
        initDrm(playerEl, playerData)
      } else {
        playerEl.src({
          src: manifestUrl,
          type: 'application/x-mpegURL'
        })
      }
      onReady && onReady(playerEl)
    } else {
      if (!player) return
      const currentSource = player?.currentSource()?.src
      if (currentSource === src) return
      if (isDrmQnet) {
        initDrm(player, playerData)
      } else {
        player.src(src)
      }
      playerRef.current = null
    }
  }, [manifestUrl])

  React.useEffect(() => {
    return () => {
      const playerEl = playerRef.current
      if (playerEl) {
        playerEl.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video
        id={videoIdTag || 'vimai-video-player'}
        ref={videoRef}
        className='video-js vjs-default-skin vjs-fill'
      />
    </div>
  )
}

export default VideoLiveJS
